@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
        .text-shadow {
          text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        }
        .text-shadow-md {
          text-shadow: 4px 4px 8px black;
        }
        .text-shadow-lg {
          text-shadow: 15px 15px 30px black;
        }
        .text-shadow-none {
          text-shadow: none;
        }
    } 
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}
#root {
  overflow-y: auto;
}

*:focus {
  outline: 0 !important;
}
.control-dots {
  bottom: -40px!important;
}
.carousel.carousel-slider{
  overflow: visible !important;
}
.carousel .slider-wrapper {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: -8px;
  margin-bottom: -8px;
}
